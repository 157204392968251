import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Legal Information`}</h2>
    <p>{`Permission to copy, modify and distribute
the files collectively known as the System Reference Document "SRD"
is granted solely through the use of the Open Gaming License, Version
1.0a.`}</p>
    <p>{`This material is being released using the Open Gaming License
Version 1.0a and you should read and understand the terms of that
license before using this material.`}</p>
    <p>{`The text of the Open Gaming License itself is not Open Game
Content. Instructions on using the License are provided within the
License itself.`}</p>
    <p>{`The following items are designated Product Identity, as
defined in Section 1(e) of the Open Game License Version 1.0a, and are
subject to the conditions set forth in Section 7 of the OGL, and are
not Open Content: Dungeons `}{`&`}{` Dragons, D`}{`&`}{`D, Dungeon Master,
Monster Manual, d20 System, Wizards of the Coast, d20 (when used as a
trademark), Forgotten Realms, Faer`}{`û`}{`n, character names (including
those used in the names of spells or items), places, Red Wizard of
Thay, Heroic Domains of Ysgard, Ever-Changing Chaos of Limbo, Windswept
Depths of Pandemonium, Infinite Layers of the Abyss, Tarterian Depths
of Carceri, Gray Waste of Hades, Bleak Eternity of Gehenna, Nine Hells
of Baator, Infernal Battlefield of Acheron, Clockwork Nirvana of
Mechanus, Peaceable Kingdoms of Arcadia, Seven Mounting Heavens of
Celestia, Twin Paradises of Bytopia, Blessed Fields of Elysium,
Wilderness of the Beastlands, Olympian Glades of Arborea, Concordant
Domain of the Outlands, Sigil, Lady of Pain, Book of Exalted Deeds,
Book of Vile Darkness, beholder, gauth, carrion crawler, tanar`}{`’`}{`ri,
baatezu, displacer beast, githyanki, githzerai, mind flayer, illithid,
umber hulk, yuan-ti.`}</p>
    <p>{`All of the rest of the SRD is Open Game Content as described
in Section 1(d) of the License.`}</p>
    <p>{`More information on the Open Game License can be found at `}<a parentName="p" {...{
        "href": "http://www.opengamingfoundation.org"
      }}>{`www.opengamingfoundation.org`}</a>{`
or `}<a parentName="p" {...{
        "href": "http://www.wizards.com"
      }}>{`www.wizards.com/d20`}</a>{`.`}</p>
    <p>{`The terms of the Open Gaming License Version 1.0a are as
follows:`}</p>
    <h3>{`OPEN GAME LICENSE Version 1.0a`}</h3>
    <p>{`The following text is the property of Wizards
of the Coast, Inc. and is Copyright 2000 Wizards of the Coast, Inc
("Wizards"). All Rights Reserved.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Definitions`}</strong>{`: (a)"Contributors" means the copyright
and/or trademark owners who have contributed Open Game Content;
(b)"Derivative Material" means copyrighted material including
derivative works and translations (including into other computer
languages), potation, modification, correction, addition, extension,
upgrade, improvement, compilation, abridgment or other form in which an
existing work may be recast, transformed or adapted; (c) "Distribute"
means to reproduce, license, rent, lease, sell, broadcast, publicly
display, transmit or otherwise distribute; (d)"Open Game Content" means
the game mechanic and includes the methods, procedures, processes and
routines to the extent such content does not embody the Product
Identity and is an enhancement over the prior art and any additional
content clearly identified as Open Game Content by the Contributor, and
means any work covered by this License, including translations and
derivative works under copyright law, but specifically excludes Product
Identity. (e) "Product Identity" means product and product line names,
logos and identifying marks including trade dress; artifacts; creatures
characters; stories, storylines, plots, thematic elements, dialogue,
incidents, language, artwork, symbols, designs, depictions, likenesses,
formats, poses, concepts, themes and graphic, photographic and other
visual or audio representations; names and descriptions of characters,
spells, enchantments, personalities, teams, personas, likenesses and
special abilities; places, locations, environments, creatures,
equipment, magical or supernatural abilities or effects, logos,
symbols, or graphic designs; and any other trademark or registered
trademark clearly identified as Product identity by the owner of the
Product Identity, and which specifically excludes the Open Game
Content; (f) "Trademark" means the logos, names, mark, sign, motto,
designs that are used by a Contributor to identify itself or its
products or the associated products contributed to the Open Game
License by the Contributor (g) "Use", "Used" or "Using" means to use,
Distribute, copy, edit, format, modify, translate and otherwise create
Derivative Material of Open Game Content. (h) "You" or "Your" means the
licensee in terms of this agreement.`}</li>
      <li parentName="ol"><strong parentName="li">{`The License`}</strong>{`: This License applies to any Open Game
Content that contains a notice indicating that the Open Game Content
may only be Used under and in terms of this License. You must affix
such a notice to any Open Game Content that you Use. No terms may be
added to or subtracted from this License except as described by the
License itself. No other terms or conditions may be applied to any Open
Game Content distributed using this License.`}</li>
      <li parentName="ol"><strong parentName="li">{`Offer and Acceptance`}</strong>{`: By Using the Open Game
Content You indicate Your acceptance of the terms of this License.`}</li>
      <li parentName="ol"><strong parentName="li">{`Grant and Consideration`}</strong>{`: In consideration for
agreeing to use this License, the Contributors grant You a perpetual,
worldwide, royalty-free, non-exclusive license with the exact terms of
this License to Use, the Open Game Content.`}</li>
      <li parentName="ol"><strong parentName="li">{`Representation of Authority to Contribute`}</strong>{`: If You
are contributing original material as Open Game Content, You represent
that Your Contributions are Your original creation and/or You have
sufficient rights to grant the rights conveyed by this License.`}</li>
      <li parentName="ol"><strong parentName="li">{`Notice of License Copyright`}</strong>{`: You must update the
COPYRIGHT NOTICE portion of this License to include the exact text of
the COPYRIGHT NOTICE of any Open Game Content You are copying,
modifying or distributing, and You must add the title, the copyright
date, and the copyright holder's name to the COPYRIGHT NOTICE of any
original Open Game Content you Distribute.`}</li>
      <li parentName="ol"><strong parentName="li">{`Use of Product Identity`}</strong>{`: You agree not to Use any
Product Identity, including as an indication as to compatibility,
except as expressly licensed in another, independent Agreement with the
owner of each element of that Product Identity. You agree not to
indicate compatibility or co-adaptability with any Trademark or
Registered Trademark in conjunction with a work containing Open Game
Content except as expressly licensed in another, independent Agreement
with the owner of such Trademark or Registered Trademark. The use of
any Product Identity in Open Game Content does not constitute a
challenge to the ownership of that Product Identity. The owner of any
Product Identity used in Open Game Content shall retain all rights,
title and interest in and to that Product Identity.`}</li>
      <li parentName="ol"><strong parentName="li">{`Identification`}</strong>{`: If you distribute Open Game Content
You must clearly indicate which portions of the work that you are
distributing are Open Game Content.`}</li>
      <li parentName="ol"><strong parentName="li">{`Updating the License`}</strong>{`: Wizards or its designated
Agents may publish updated versions of this License. You may use any
authorized version of this License to copy, modify and distribute any
Open Game Content originally distributed under any version of this
License.`}</li>
      <li parentName="ol"><strong parentName="li">{`Copy of this License`}</strong>{`: You MUST include a copy of
this License with every copy of the Open Game Content You Distribute.`}</li>
      <li parentName="ol"><strong parentName="li">{`Use of Contributor Credits`}</strong>{`: You may not market or
advertise the Open Game Content using the name of any Contributor
unless You have written permission from the Contributor to do so.`}</li>
      <li parentName="ol"><strong parentName="li">{`Inability to Comply`}</strong>{`: If it is impossible for You to
comply with any of the terms of this License with respect to some or
all of the Open Game Content due to statute, judicial order, or
governmental regulation then You may not Use any Open Game Material so
affected.`}</li>
      <li parentName="ol"><strong parentName="li">{`Termination`}</strong>{`: This License will terminate
automatically if You fail to comply with all terms herein and fail to
cure such breach within 30 days of becoming aware of the breach. All
sublicenses shall survive the termination of this License.`}</li>
      <li parentName="ol"><strong parentName="li">{`Reformation`}</strong>{`: If any provision of this License is
held to be unenforceable, such provision shall be reformed only to the
extent necessary to make it enforceable.`}</li>
      <li parentName="ol"><strong parentName="li">{`COPYRIGHT NOTICE`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Open Game License v 1.0a Copyright 2000, Wizards of the
Coast, Inc.`}</li>
      <li parentName="ul">{`System Reference Document Copyright 2000-2003, Wizards of
the Coast, Inc.; Authors Jonathan Tweet, Monte Cook, Skip Williams,
Rich baker, Andy Collins, David noonan, Rich Redman, Bruce R. Cordell,
based on original material by E. Gary Gygax and Dave
Arneson.`}</li>
      <li parentName="ul">{`Unearthed Arcana Copyright 2004, Wizards of the Coast, Inc.; Andy Collins,
Jesse Decker, David Noonan, Rich Redman.`}</li>
      <li parentName="ul">{`System Reference Document v3.5
HTML compilation Copyright 2007, Sovelior Meliamne `}{`&`}{` Jason Olaf
Jensen.`}</li>
      <li parentName="ul">{`Generic Character Sheet 2020, Wilson Gearld Mead`}</li>
    </ul>
    <p>{`END OF LICENSE`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      